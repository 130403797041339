<template>
  <div class="page">
    <div class="content">
      <p>
        <w-icon>mdi mdi-wrench</w-icon>
        （現在、作成中です）
      </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
